/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { hasPermission } from 'shared/helpers';
import { Button, OAuthCredentials, OAuthModals } from 'shared/components';
// import { userPermissions } from 'shared/constants';
import { useOAuth } from 'shared/hooks';
import {
  getOauthLicenseClient,
  createOauthLicenseClient,
  deleteOauthLicenseClient,
  createOauthLicenseClientSecret,
  rotateOauthLicenseClientSecret,
} from './actions';

const LicenseOAuth = ({ licenseID, companyID }) => {
  const permissionsList = useSelector((state) =>
    get(state, 'user.details.permissions'),
  );
  const canManageOAuth = hasPermission(
    permissionsList,
    'manage_license_oauth_clients',
  );
  console.log('canManageOAuth', canManageOAuth);

  const handleManageSettingsClick = (cb) => {
    cb();
    return true;
  };

  const {
    isLoading,
    loadingCreateClient,
    oAuthClient,
    isRotateSecretModalOpen,
    setRotateSecretModalOpen,
    isRotateConfirmationOpen,
    setRotateConfirmationOpen,
    isRefreshConfirmationOpen,
    setRefreshConfirmationOpen,
    isDeleteConfirmationOpen,
    setDeleteConfirmationOpen,
    rotateSecretData,
    setRotateSecretData,
    getOauthClient,
    createNewOauthClient,
    handleDeleteOauthClient,
    handleRefreshSecret,
    handleRotateSecret,
  } = useOAuth({
    entityId: licenseID,
    companyID,
    getClient: getOauthLicenseClient,
    createClient: createOauthLicenseClient,
    deleteClient: deleteOauthLicenseClient,
    refreshSecret: createOauthLicenseClientSecret,
    rotateSecret: rotateOauthLicenseClientSecret,
  });

  const { t } = useTranslation();
  const handleRotateClick = () => {
    setRotateSecretModalOpen(true);
  };

  const handleRefreshClick = () => {
    setRefreshConfirmationOpen(true);
  };

  const handleDeleteClick = () => {
    setDeleteConfirmationOpen(true);
  };

  return (
    <div className="OAuth">
      <div className="row">
        <h3>{t('License OAuth Configuration')}</h3>
        {!oAuthClient && canManageOAuth && (
          <Button
            size="sm"
            theme="info"
            loading={loadingCreateClient}
            disabled={loadingCreateClient || isLoading}
            onClick={() => handleManageSettingsClick(createNewOauthClient)}
          >
            {t('Initialize OAuth')}
          </Button>
        )}
        {!oAuthClient && !canManageOAuth && (
          <p>
            OAuth is not initialized for this license, and you do not have
            manage permissions. Contact your admin to initialize oAuth for you
            or give you manage permissions.
          </p>
        )}
        {oAuthClient && (
          <OAuthCredentials
            oAuthClient={oAuthClient}
            isLoading={isLoading}
            handleManageSettingsClick={handleManageSettingsClick}
            onRotateClick={handleRotateClick}
            onRefreshClick={handleRefreshClick}
            onDeleteClick={handleDeleteClick}
            setRefreshConfirmationOpen={setRefreshConfirmationOpen}
            setRotateConfirmationOpen={setRotateConfirmationOpen}
            setDeleteConfirmationOpen={setDeleteConfirmationOpen}
          />
        )}
      </div>

      <OAuthModals
        isLoading={isLoading}
        isDeleteConfirmationOpen={isDeleteConfirmationOpen}
        setDeleteConfirmationOpen={setDeleteConfirmationOpen}
        handleDeleteOauthClient={handleDeleteOauthClient}
        isRotateConfirmationOpen={isRotateConfirmationOpen}
        setRotateConfirmationOpen={setRotateConfirmationOpen}
        isRefreshConfirmationOpen={isRefreshConfirmationOpen}
        setRefreshConfirmationOpen={setRefreshConfirmationOpen}
        isRotateSecretModalOpen={isRotateSecretModalOpen}
        setRotateSecretModalOpen={setRotateSecretModalOpen}
        handleRotateSecret={handleRotateSecret}
        handleRefreshSecret={handleRefreshSecret}
        getOauthClient={getOauthClient}
        rotateSecretData={rotateSecretData}
        setRotateSecretData={setRotateSecretData}
      />
    </div>
  );
};
LicenseOAuth.propTypes = {
  licenseID: PropTypes.string.isRequired,
  companyID: PropTypes.string.isRequired,
};

export default LicenseOAuth;
