import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { ClipboardRow, List } from 'shared/components';
import { hasPermission } from 'shared/helpers';
import { LuRotateCw, LuRefreshCcwDot, LuTrash2 } from 'react-icons/lu';
import { useTranslation } from 'react-i18next';
import './styles.scss';

const IconButton = ({ icon: Icon, onClick, disabled, className }) => (
  <button
    type="button"
    onClick={onClick}
    disabled={disabled}
    className={`icon-button ${className || ''}`}
  >
    <Icon />
  </button>
);

IconButton.propTypes = {
  icon: PropTypes.elementType.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

IconButton.defaultProps = {
  disabled: false,
  className: '',
};

export const OAuthCredentials = ({
  oAuthClient,
  isLoading,
  handleManageSettingsClick,
  onRotateClick,
  onRefreshClick,
  onDeleteClick,
}) => {
  const { t } = useTranslation();
  const permissionsList = useSelector((state) =>
    get(state, 'user.details.permissions'),
  );
  const canManageOAuth = hasPermission(
    permissionsList,
    'manage_license_oauth_clients',
  );

  const columns = [
    {
      Header: t('Client ID'),
      sortable: false,
      accessor: 'clientId',
      Cell: (cellData) => (
        <div className="credential-cell">
          <ClipboardRow
            id="clientID"
            value={get(cellData, 'value')}
            hideLabel
            label={t('Client ID')}
          />
        </div>
      ),
    },
    {
      Header: t('Client Secret'),
      sortable: false,
      accessor: 'secret',
      Cell: (cellData) => (
        <div className="credential-cell">
          <ClipboardRow
            id="clientSecret"
            value={get(cellData, 'value')}
            hideLabel
            label={t('Client Secret')}
          />
        </div>
      ),
    },
  ];

  if (canManageOAuth) {
    columns.push(
      {
        Header: t('Refresh'),
        sortable: false,
        className: 'text-center',
        headerClassName: 'text-center',
        accessor: 'actions',
        width: 100,
        Cell: () => (
          <div className="credential-actions">
            <IconButton
              icon={LuRotateCw}
              onClick={() => handleManageSettingsClick(onRefreshClick)}
              disabled={isLoading}
              className="icon-button--refresh"
            />
          </div>
        ),
      },
      {
        Header: t('Rotate'),
        sortable: false,
        className: 'text-center',
        headerClassName: 'text-center',
        accessor: 'actions',
        width: 100,
        Cell: () => (
          <div className="credential-actions">
            <IconButton
              icon={LuRefreshCcwDot}
              onClick={() => handleManageSettingsClick(onRotateClick)}
              disabled={isLoading}
              className="icon-button--rotate"
            />
          </div>
        ),
      },
      {
        Header: t('Delete'),
        sortable: false,
        className: 'text-center',
        headerClassName: 'text-center',
        accessor: 'actions',
        width: 100,
        Cell: () => (
          <div className="credential-actions">
            <IconButton
              icon={LuTrash2}
              onClick={() => handleManageSettingsClick(onDeleteClick)}
              disabled={isLoading}
              className="icon-button--delete"
            />
          </div>
        ),
      },
    );
  }

  return (
    <div className="oauth-actions">
      <List
        columns={columns}
        data={[oAuthClient]}
        minRows={1}
        showPagination={false}
        loading={isLoading}
        clickable={false}
      />
    </div>
  );
};

OAuthCredentials.propTypes = {
  oAuthClient: PropTypes.shape({
    clientId: PropTypes.string.isRequired,
    secret: PropTypes.string.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleManageSettingsClick: PropTypes.func.isRequired,
  onRefreshClick: PropTypes.func.isRequired,
  onRotateClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
};

export default OAuthCredentials;
